@import '/src/components/GlobalStyles/variable.scss';
.partner-swiper__slide {
  border-bottom: solid 1px $gray;
  &:not(:last-child) {
    border-right: solid 1px $gray;
  }
  &__icon {
    width: 32px;
    aspect-ratio: 1/1;
  }
}
