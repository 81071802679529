@import '../../../components/GlobalStyles//variable.scss';
.sec-3 {
  background: linear-gradient(260deg, #dadce7, #f5f6fc);
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(#000, 0.15);
    z-index: 1;
  }
  &__hero {
    color: #fff;
    &__animate {
      position: absolute;
      top: 50%;
      width: 100%;
      right: 0;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 0;
    }
    &__content {
      position: relative;
      z-index: 2;
    }
  }

  &__tabs {
    position: relative;
    z-index: 2;
    font-size: 24px;

    &__tab {
      border-left: solid 1px white;
      position: relative;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      &:last-child {
        border-right: solid 1px white;
      }
      &::after {
        content: '';
        border-radius: 100%;
        width: 56px;
        height: 56px;
        background: url(../../../assets/images/site/arrow.png) no-repeat;
        background-position: center center;
        background-color: #000;
        visibility: hidden;
      }

      &:hover,
      &--active {
        background-color: #fff;
        transition: ease-out 0.5s;
        span {
          color: #000;
        }
      }
      &--active {
        &::after {
          visibility: visible;
        }
      }
    }
  }
}

.api-dashboard {
  display: flex;
  width: 100%;
  aspect-ratio: 16/9;
}

@media (max-width: 767px) {
  .sec-3 {
    &__hero {
      &__animate {
        max-width: unset;
        width: 200%;
      }
    }
  }
}
