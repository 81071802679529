@import 'variable.scss';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@20100;400;600;700&family=Poppins:wght@300&display=swap');
@import '../../assets/styles/tailwind.scss';

:root {
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  font-family: 'Inter', sans-serif;
}

.t {
  &--hl {
    font-size: 24px;
    font-style: normal;
    line-height: 60px;

    i {
      font-weight: 700;
      color: $primary;
    }
  }
  &--head {
    font-size: 211px;
    font-style: normal;
    font-weight: 400;
    line-height: 176px; /* 83.412% */
    letter-spacing: -2.2px;
  }

  &--gray {
    color: $gray;
  }
}

.decor {
  &--line {
    background: url(../../assets/images/site/bg-1.png) no-repeat;
  }
  &--round {
    background: url(../../assets/images/site/bg-2.png) repeat-x;
    background-size: 100%;
  }
  &--1-round {
    background: url(../../assets/images/site/bg-2.png) repeat-x;
    background-size: auto 50%;
  }
  &--spiral {
    position: relative;
    z-index: 2;
    &::after {
      content: '';
      position: absolute;
      background: url(../../assets/images/banners/banner-6.png) no-repeat;
      width: 300px;
      height: 100%;
      right: 0;
      top: 0;
      z-index: 0;
      object-fit: contain;
      object-position: center;
      z-index: 1;
    }
  }
}

.sec-desc {
  font-size: 86px;
  line-height: 77px;

  span {
    color: $gray;
  }
}

.link {
  font-size: 48px;
  font-style: normal;
  font-weight: 400;

  &:hover {
    color: $primary;
    transition: ease-out 0.3s;
  }
}

/* rotationAnimation.css */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spin {
  animation: spin 2s linear infinite; /* Adjust the animation duration (2s) as needed */
}

@media (max-width: 767px) {
  .sec-desc {
    font-size: 24px;
    line-height: normal;
  }
  .t {
    &--hl {
      font-size: 14px;
      line-height: normal;
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
    &--head {
      font-size: 64px;
      line-height: normal;
    }
  }

  .link {
    font-size: 20px;
    line-height: normal;
  }

  .decor {
    &--spiral {
      &::after {
        transform: translateY(-30%);
        width: 200px;
      }
    }
  }
}
