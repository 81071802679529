@import '../../../components/GlobalStyles/variable.scss';
.influence {
  font-size: 24px;
  transition: ease-out 0.3s;
  &:hover {
    color: $primary;
    margin-left: 16px;
  }
}

.tr-videos {
  &__video {
    width: 33%;
    display: flex;
    margin-bottom: 30px;
    &__thumb {
      width: 84px;
      aspect-ratio: 1;
      object-fit: cover;
      overflow: hidden;
      border-radius: 7px;
    }
  }
}

@media (max-width: 767px) {
  .tr-videos {
    &__video {
      width: 100%;
    }
  }
  .ctrl-btn {
    padding: 15px!important;
  }
}
