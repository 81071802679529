@import '../../../GlobalStyles/variable.scss';
.footer {
  background: $gray;
  color: #fff;
  font-size: 24px;

  &__socials {
    &__item {
      transition: ease-out 0.3s;
      &:not(:first-child) {
        margin-left: 30px;
        img {
          width: 36px;
        }
      }

      &:hover {
        color: $primary;
      }
    }
  }

  &__copyright {
    padding: 40px;
    border-top: 1px #fff solid;
  }
}

.super-input {
  display: flex;
  background-color: white;
  border: none;
  border-radius: 100px;
  justify-content: space-between;
  padding: 20px;

  input {
    color: #000;
    border: none;
    outline: none;
    font-size: 24px;
    padding: 5px 30px;
    width: 100%;
    background: transparent;
  }
}

@media (max-width: 767px) {
  .super-input {
    padding: 5px;

    input {
      font-size: 14px;
    }
    button {
      padding: 10px !important;
    }
  }
}
