@import '../../../GlobalStyles/variable.scss';
.step-list {
  height: fit-content;
}
.step {
  position: relative;
  padding: 20px;
  border: solid $gray 0.5px;
  border-radius: 10px;
  aspect-ratio: 3/4;
  overflow: hidden;

  &::after {
    position: absolute;
    content: '';
    background: url(../../../../assets/images/icons/icon-star.png) no-repeat;
    width: 32px;
    aspect-ratio: 1;
    background-position: center;
    right: 20px;
    top: 20px;
    visibility: hidden;
    animation: spin 2s linear infinite;
  }
  &:hover::after {
    visibility: visible;
  }

  &:hover {
    background-color: #000;
    transition: ease-out 0.3s;
    color: #fff;
    .step {
      &__image {
        transition: ease-out 0.3s;
        max-width: 120px;
      }
      &__desc {
        transition: ease-out 0.3s;
        height: unset;
        overflow: unset;
      }
    }

    button {
      display: flex;
      background-color: #fff !important;
      color: #000 !important;
    }
  }
  &__image {
    margin: 0 auto;
    width: 100%;
    aspect-ratio: 1;
    object-fit: contain;
  }
  &__title {
    height: 100%;
    display: flex;
    align-items: flex-end;
  }
  &__desc {
    height: 0;
    overflow: hidden;
  }

  button {
    display: none;
  }
}

/* rotationAnimation.css */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 767px) {
  .step {
    &:hover {
      .step {
        &__image {
          max-width: 100px;
        }
      }
    }
  }
}
