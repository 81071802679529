@import '../../../GlobalStyles/variable.scss';
.button {
  display: flex;
  width: fit-content;
  min-width: 250px;
  position: relative;
  white-space: nowrap;
  &::after,
  &::before {
    content: '';
    border-radius: 100%;
    height: 100%;
    aspect-ratio: 1/1;
    background: url(../../../../assets/images/site/arrow.png) no-repeat;
    background-position: center center;
    transition: all 0.5s;
    margin: auto;
    background-color: $primary;
  }
  &::after {
    height: 0;
  }
  &:hover::before {
    animation-name: atrophy;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }
  &:hover::after {
    animation-name: enlarge;
    animation-duration: 0.5s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }

  button {
    background-color: $primary;
    width: 100%;
  }

  &--black {
    &::after,
    &::before,
    button {
      background-color: #000;
    }
  }
}

@keyframes atrophy {
  100% {
    height: 0;
  }
}
@keyframes enlarge {
  100% {
    height: 100%;
  }
}
