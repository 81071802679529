@import '../../../../components/GlobalStyles/variable.scss';
.creator-list {
  background: url(../../../../assets/images/banners/banner-1.png) no-repeat;
  background-position: center;
  background-size: cover;

  &__item {
    background-color: $gray;

    &:hover {
      transition: ease-out 0.3s;
      background-color: rgba($color: #000000, $alpha: 0.75);
      color: #fff;

      .creator-list__item__name {
        transition: ease-out 0.3s;
        padding-left: 50px;
      }
      .creator-list__item__desc {
        transition: ease-out 0.3s;
      }
    }
  }
}

@media (max-width: 767px) {
  .creator-list {
    &__item {
      border-top: solid #fff 0.5px;
      border-bottom: solid #fff 0.5px;
      &:hover {
        .creator-list__item__name {
          padding-left: unset;
        }
      }
    }
  }
}
