@import '../../../components/GlobalStyles/variable.scss';
.sec-6 {
  background: #f4f4f4 !important;
}
.faq {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
}

@media (max-width: 767px) {
  .faq {
    button {
      padding: 10px !important;
    }
    svg {
      width: 18px;
      height: 18px;
    }
  }
}
