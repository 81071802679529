@import '../../../GlobalStyles/variable.scss';
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.header {
  background-color: #fff;
  box-shadow: rgba($gray, 0.15) 0px 20px 50px 0px;
  &__actions {
    &__sidebar {
      margin-right: 0.5rem;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    &__close {
      display: none;
      width: 56px;
      background-color: #fff !important;
      box-shadow: rgba($gray, 0.15) 0px 20px 50px 0px;
      padding: 20px !important;
      margin-bottom: 50px;
    }

    &__menu {
      padding: 15px !important;
      margin-left: 10px;
      display: none;
      img {
        height: 10px !important;
        width: auto;
      }
    }
  }
}
.header-hide {
  transition: all 0.3s;
  transform: translateY(-100%);
}

.header-top {
  background-color: transparent;
  box-shadow: unset;
}
@media (max-width: 1079px) {
}

@media (max-width: 767px) {
  .header {
    background-color: #fff;
    box-shadow: rgba($gray, 0.15) 0px 20px 50px 0px;

    &__actions {
      &__sidebar {
        position: fixed;
        background-color: $gray-light;
        top: 0;
        right: 0;
        bottom: 0;
        left: 20px;
        margin-right: 0;
        z-index: 10;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 20px;
        transform: translateX(100%);
        transition: ease-out 0.3s;

        &--active {
          transform: unset;
          overflow: hidden;
        }
      }

      &__button {
        background-color: transparent !important;
        color: #000 !important;
        padding: 0 !important;
        margin-top: 20px;

        &:hover {
          background-color: transparent !important;
        }
      }

      &__close,
      &__menu {
        display: block;
      }
    }

    &__logo {
      width: 100px;
      height: auto;
    }
  }
}
