@import '../../../components/GlobalStyles/variable.scss';

.sec-2 {
  //   margin-top: calc(9 * (1536px - 5rem) / 16) !important;
  margin-top: 500px !important;
  &::before {
    content: '';
    position: absolute;
    width: 100px;
    height: 100px;
    background: url(../../../assets/images/site/star.png) no-repeat;
    right: 40px;
    top: 40px;
    background-position: center;
    animation: spin 2s linear infinite;
  }

  &__cta {
    min-height: 600px;
    display: flex;
    background: url(../../../assets/images/banners/banner-2.png) no-repeat;
    background-size: contain;
    background-position: center center;
  }
}

/* rotationAnimation.css */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 767px) {
  .sec-2 {
    margin-top: 400px !important;
  }
}
