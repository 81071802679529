@import '../../../components/GlobalStyles/variable.scss';
.fullscreen-element {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #f0f0f0;
  transition: height 0.5s ease;
}

.normal-element {
  height: auto;
  background-color: #ffffff;
}
.intro-video {
  position: fixed;
  right: 5rem;
  top: 50%;
  max-width: 100%;
  width: 100px;
  z-index: 0;
  transform: translateY(-50%);
  transform-origin: center center;
  overflow: hidden;

  &:hover {
    .intro-video__overlay {
      visibility: visible;
    }
  }
  video {
    max-height: 500px;
    width: 100%;
    object-fit: cover;
  }

  &__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.25);
    visibility: hidden;

    button {
      width: 100px;
      aspect-ratio: 1/1;
      border-radius: 100%;
      background-color: rgba(0, 0, 0, 0.75);
      color: #fff;

      &:hover {
        transform: scale(1.1);
        transition: ease-out 0.3s;
        background: rgba($color: $primary, $alpha: 0.75);
      }
    }
  }
}

@media (max-width: 767px) {
  .intro-video {
    &__overlay {
      button {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        aspect-ratio: unset;
        border-radius: unset;
        visibility: visible;
        background-color: rgba(0, 0, 0, 0.2);
      }
    }
  }
}
