@import '../../../GlobalStyles/variable.scss';
.rounded-button {
  transition: ease-out 0.3s;
  border-radius: 100px;
  width: fit-content;
  &:not(.rounded-button--active):hover {
    background-color: $primary;
    border-color: $primary;
  }
}
