@import '../../../GlobalStyles/variable.scss';
.language-switch {
  transition: ease-out 0.3s;
  border-radius: 100px;
  width: fit-content;
  border: solid 1px #000;
  padding: 1rem 2rem;
  font-size: 16px;
  color: #000 !important;
  background-color: #fff;
  position: relative;
  min-width: 150px;

  &__list {
    position: absolute;
    box-shadow: rgba($gray, 0.75) 0px 40px 100px 0px;
    top: -1px;
    right: -1px;
    left: -1px;
    border-radius: 20px;
    width: fit-content;
    font-size: 16px;
    border-radius: 26px;
    border: solid 1px #f1f1f1;
    background: #f1f1f1 !important;
    cursor: pointer;
    height: 0;
    border: none;
    overflow: hidden;
    &--show {
      width: calc(100% + 2px);
      height: unset;
    }
    li {
      padding: 15px;
      transition: ease-out 0.3s;
      &:hover {
        color: $primary;
      }
    }
  }
}
