@import '../../../GlobalStyles/variable.scss';
.circle-button {
  aspect-ratio: 1/1;
  border-radius: 100%;
  transition: ease-out 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: $primary !important;
  }
}
