@import '../../../GlobalStyles/variable.scss';
.stat {
  border-bottom: solid 0.5px #ffffff5c;
  border-right: solid 0.5px #ffffff5c;
  aspect-ratio: 1;
  &:first-child {
    border-left: solid 0.5px #ffffff5c;
  }
  &__plus {
    color: $primary;
  }

  &__img {
    width: 50%;
    aspect-ratio: 1/1;
    border-radius: 100%;
    overflow: hidden;
    transition: 0.3s ease-in-out;

    &:hover {
      width: 100%;
      border-radius: unset;
    }
  }
}
@media (max-width: 767px) {
  .stat {
    &:nth-child(2n + 1) {
      border-left: solid 0.5px #ffffff5c;
    }
    &:not(:nth-child(-n + 2)) {
      margin-top: 24px;
    }
  }
}
