@import '/src/components/GlobalStyles/variable.scss';
.kol-swiper__slide {
  &__image {
    border-radius: 20px;
  }

  &__name {
    transition: ease-out 0.3s;
    &:hover {
      color: $primary;
    }
  }
}
