@import '../../../components/GlobalStyles/variable.scss';
.sec-5 {
  &__heading {
    width: 100%;
    font-size: 150px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 40px;
  }
}

@media (max-width: 767px) {
  .sec-5 {
    &__heading {
      font-size: 36px;
      padding: 20px;
    }
  }
}
